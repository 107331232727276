import React, { useContext, Fragment, useState } from "react";
import { Category } from "../Components/Category";
import { Navbar } from "../Components/Navbar";
import { TopHeader } from "../Components/TopHeader";
import { BtmFooter } from "../Components/BtmFooter";
import { Cartcontext } from "../context/context";
import { Link } from "react-router-dom";

function Cart() {
  const Globalstate = useContext(Cartcontext);
  const dispatch = Globalstate.dispatch;
  const state = Globalstate.state;
  const total = state.reduce((total, item)=>{
    return total+item.price*item.qty;
  }, 0);

  return (
    <Fragment>
      <TopHeader />
      <div className="container-fluid">
        <div className="row border-top px-xl-5">
          <Category collapsed={false} />
          <div className="col-lg-9">
            <Navbar />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "300px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
            Shopping Cart
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <a href="/">Home</a>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Shopping Cart</p>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-5">
        <div className="row px-xl-5">
          <div className="col-lg-8 table-responsive mb-5">
            <table className="table table-bordered text-center mb-0">
              <thead className="bg-secondary text-dark">
                <tr>
                  <th>Products</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {state.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">
                        <img
                          src={item.image}
                          alt={item.name}
                          style={{ width: "50px" }}
                        />&nbsp;&nbsp;
                        {item.name}
                      </td>
                      <td className="align-middle">₹ {item.price}</td>
                      <td className="align-middle">
                        <div
                          className="input-group quantity mx-auto"
                          style={{ width: "100px" }}
                        >
                          <div className="input-group-btn">
                            <button
                              className="btn btn-sm btn-primary btn-minus"
                              onClick={() => {
                                if (item.qty > 1) {
                                  dispatch({ type: "DECREASE", payload: item });
                                } else {
                                  const userChoice = window.confirm("This will reomve item from cart. Do you want to proceed?");
                                  if (userChoice) {
                                    dispatch({type:"REMOVE", payload:item});
                                  } else {
                                    alert("You chose to Cancel!");
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <span className="form-control form-control-sm bg-secondary text-center">
                            {item.qty}
                          </span>
                          <div className="input-group-btn">
                            <button
                              className="btn btn-sm btn-primary btn-plus"
                              onClick={() =>
                                dispatch({ type: "INCREASE", payload: item })
                              }
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                          
                        </div>
                      </td>
                      <td className="align-middle">
                        ₹ {item.qty * item.price}
                      </td>
                      <td className="align-middle">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            dispatch({ type: "REMOVE", payload: item })
                          }
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col-lg-4">
            <form className="mb-5" action="">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control p-4"
                  placeholder="Coupon Code"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary">Apply Coupon</button>
                </div>
              </div>
            </form>
            <div className="card border-secondary mb-5">
              <div className="card-header bg-secondary border-0">
                <h4 className="font-weight-semi-bold m-0">Cart Summary</h4>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3 pt-1">
                  <h6 className="font-weight-medium">Subtotal</h6>
                  <h6 className="font-weight-medium">₹ {total}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="font-weight-medium">Shipping</h6>
                  <h6 className="font-weight-medium">₹ 100</h6>
                </div>
              </div>
              <div className="card-footer border-secondary bg-transparent">
                <div className="d-flex justify-content-between mt-2">
                  <h5 className="font-weight-bold">Total</h5>
                  <h5 className="font-weight-bold">₹ {total + 100}</h5>
                </div>
                  <Link to='/checkout' className="btn btn-block btn-primary my-3 py-3">Proceed To Checkout</Link>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <BtmFooter />
    </Fragment>
  );
}
export default Cart;
