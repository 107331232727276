import React, { useEffect, useState } from "react";
import axios from "axios";

export function Category({ collapsed }) {
  const [categories, setCategories] = useState([]);
  const url = "https://ecomdatabase.orojewels.com/api/APIProduct/ProductList";
  //const url = "https://api.pujakaitem.com/api/products";

  useEffect(() => {
    // Make the API request
    dispCategories();
  }, [0]);
  const dispCategories=()=>{
    axios
      .get(url)
      .then((response) => {
        // Parse the JSON response into an array of objects
        const data = response.data;
        // Extract distinct categories
        const distinctCategories = [
          ...new Set(data.map((item) => item.category)),
        ];
        setCategories(distinctCategories);        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="col-lg-3 d-none d-lg-block">
      <a
        className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100"
        data-toggle="collapse"
        href="#navbar-vertical"
        style={{ height: "65px", marginTop: "-1px", padding: "0 30px" }}
      >
        <h6 className="m-0">Categories</h6>
        <i className="fa fa-angle-down text-dark"></i>
      </a>

      {collapsed ? (
        <nav
          className="collapse show app-header navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 
          border-bottom-0"
          role="navigation"
          id="navbar-vertical"
        >
          <div
            className="navbar-nav w-100 overflow-hidden"
            style={{ height: "410px" }}
          >
            {categories.map((category) => (
              <a href="/" className="nav-item nav-link" key={category}>
                {category}
              </a>
            ))}
          </div>
        </nav>
      ) : (
        <nav
          className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 border 
          border-top-0 
          border-bottom-0 bg-light"
          id="navbar-vertical"
          style={{ width: 'calc(100% - 30px)', zIndex: '1' }}
        >
          <div className="navbar-nav w-100 overflow-hidden" style={{height: '410px'}}>
            {categories.map((category) => (
              <a href="/" className="nav-item nav-link" key={category}>
                {category}
              </a>
            ))}
          </div>
        </nav>
      )}
    </div>
  );
}
