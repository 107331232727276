import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export function Navbar() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const url = "https://ecomdatabase.orojewels.com/api/APIProduct/ProductList";

  useEffect(() => {
    // Make the API request
    dispCategories();
  }, [0]);

  const dispCategories = () => {
    axios
      .get(url)
      .then((response) => {
        // Parse the JSON response into an array of objects
        const data = response.data;
        // Extract distinct categories
        const distinctCategories = [
          ...new Set(data.map((item) => item.category)),
        ];
        setCategories(distinctCategories);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
        <a href="" className="text-decoration-none d-block d-lg-none">
          <h1 className="m-0 display-5 font-weight-semi-bold">
            <span className="text-primary font-weight-bold border px-3 mr-1">
              E
            </span>
            Shopper
          </h1>
        </a>

        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarCollapse"
        >
          <div className="navbar-nav mr-auto py-0">
            <NavLink to="/" className="nav-item nav-link">
              Home
            </NavLink>
            <br />
            <NavLink to="/AboutUs" className="nav-item nav-link">
              About Us
            </NavLink>
            <NavLink to="/Shop" className="nav-item nav-link">
              All Jewellery
            </NavLink>
            <NavLink to="/Contact" className="nav-item nav-link">
              Contact
            </NavLink>
          </div>
          <div className="navbar-nav ml-auto py-0">
            {isAuthenticated ? (                   
              <NavLink
                className="nav-item nav-link"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
               Log Out
              </NavLink>                 
            ) : (
              <NavLink
                className="nav-item nav-link"
                onClick={() => loginWithRedirect()}
              >
                Log In
              </NavLink>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
