import React from "react";
import { NavLink } from "react-router-dom";


export function BtmFooter(){
    return(
        <>
        <div className="container-fluid bg-secondary">
                <div className="row justify-content-md-center ">
                    <div className="col-md-6 col-12 py-5">
                        <div className="text-center mb-2 pb-2">
                            <h2 className="section-title px-5 mb-3"><span className="bg-secondary px-2">Stay Updated</span></h2>
                            <p>Amet lorem at rebum amet dolores. Elitr lorem dolor sed amet diam labore at justo ipsum eirmod duo labore labore.</p>
                        </div>
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control border-white p-4" placeholder="Email Goes Here" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary px-4">Subscribe</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         <div className="container-fluid bg-secondary text-dark">
                <div className="row px-xl-5 pt-5">
                    <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                        <a href="/" className="text-decoration-none">
                            <h1 className="mb-4 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border border-white px-3 mr-1">E</span>Shopper</h1>
                        </a>
                        <p>Dolore erat dolor sit lorem vero amet. Sed sit lorem magna, ipsum no sit erat lorem et magna ipsum dolore amet erat.</p>
                        <p className="mb-2"><i className="fa fa-map-marker-alt text-primary mr-3"></i>123 Street, New York, USA</p>
                        <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>info@example.com</p>
                        <p className="mb-0"><i className="fa fa-phone-alt text-primary mr-3"></i>+012 345 67890</p>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-md-4 mb-5">
                                <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
                                <div className="d-flex flex-column justify-content-start">
                                <NavLink to="/" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Home</NavLink>
                                <NavLink to="/AboutUs" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>About Us</NavLink>
                                <NavLink to="/Shop" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>All Jewellery</NavLink> 
                                <NavLink to="/Contact" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Contact</NavLink>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
                                <div className="d-flex flex-column justify-content-start">
                                <NavLink to="/" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Home</NavLink>
                                <NavLink to="/AboutUs" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>About Us</NavLink>
                                <NavLink to="/Shop" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>All Jewellery</NavLink> 
                                <NavLink to="/Contact" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Contact</NavLink>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <h5 className="font-weight-bold text-dark mb-4">Newsletter</h5>
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" className="form-control border-0 py-4" placeholder="Your Name" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control border-0 py-4" placeholder="Your Email"
                                            required="required" />
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-block border-0 py-3" type="submit">Subscribe Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row border-top border-light mx-xl-5 py-4">
                    <div className="col-md-6 px-xl-0">
                        <p className="mb-md-0 text-center text-md-left text-dark">
                            &copy; <a className="text-dark font-weight-semi-bold" href="/">Your Site Name</a>. All Rights Reserved. Designed
                            by
                            <a className="text-dark font-weight-semi-bold" href="https://htmlcodex.com">
                                HTML Codex
                            </a>
                            <br />
                            Distributed By{' '}
                            <a href="https://themewagon.com" target="_blank" rel="noopener noreferrer">
                                ThemeWagon
                            </a>
                        </p>
                    </div>
                    <div className="col-md-6 px-xl-0 text-center text-md-right">
                        <img className="img-fluid" src="assets/img/payments.png" alt="" />
                    </div>
                </div>
            </div>

            <a href="/" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>
        </>
    );
}