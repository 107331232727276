import React, { useState, useEffect, useContext, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cartcontext } from '../context/context';

export function OffersDiv(){
  const [counter, setCounter] = useState(1);
  const Globalstate = useContext(Cartcontext);
  const dispatch = Globalstate.dispatch;
  const url = "https://ecomdatabase.orojewels.com/api/APIProduct/ProductList"; //api url  
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {    
      try {
         fetch(url)
        .then((resp) => resp.json())
        .then((resp) =>
        setCategories(
          resp.filter((item) => item.category ))
        );
      } catch (error) {
        
      }
    })       

   function getSelectedItem(itemId){
    navigate("/detail", {
     state: {
         selectedItem : itemId,
         detailCat : 'this is my fav',
     },
  });
 }

    return (
      <div className="Container" id="container">
        <div className="container-fluid pt-5">
          <div className="text-center mb-4">
            <h2 className="section-title px-5">
              <span className="px-2">Trendy Products</span>
            </h2>
          </div>
          <div className="row px-xl-5 pb-3">
          {categories.map((category) => (
            category.qty = counter,
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1" key={category.id} >    
                <div className="card product-item border-0 mb-4">
                <div onClick={() => getSelectedItem(category.id)}>
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src={category.image} alt="" />
                  </div>                 
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">
                    {category.name}
                    </h6>
                    <div className="d-flex justify-content-center">
                      <h6>₹ {category.price - 1000}</h6>
                      <h6 className="text-muted ml-2">
                        <del>₹ {category.price}</del>
                      </h6>
                    </div>
                  </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                  <span className="btn btn-sm text-dark p-0">
                        <i className="fas fa-cogs text-primary mr-1"></i>
                        {category.company}
                      </span>
                    <label className="btn btn-sm text-dark p-0" onClick={()=>dispatch({type : "ADD", payload: category})}>
                      <i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</label>
                  </div>
                </div>              
            </div>
            ))}
          </div>
        </div>
        <div className="container-fluid pt-5">
          <div className="text-center mb-4">
            <h2 className="section-title px-5">
              <span className="px-2">Just Arrived</span>
            </h2>
          </div>
          <div className="row px-xl-5 pb-3">
          {categories.map((category) => (
            category.qty = counter,
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1" key={category.id} >
              <div className="card product-item border-0 mb-4">
                <div onClick={() => getSelectedItem(category.id)}>
                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                  <img className="img-fluid w-100" src={category.image} alt="" />
                </div>
                <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                  <h6 className="text-truncate mb-3">{category.name}</h6>
                  <div className="d-flex justify-content-center">
                    <h6>₹ {category.price - 1000}</h6>
                    <h6 className="text-muted ml-2">
                      <del>₹ {category.price}</del>
                    </h6>
                  </div>
                </div>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light border">
                <span className="btn btn-sm text-dark p-0">
                        <i className="fas fa-cogs text-primary mr-1"></i>
                        {category.company}
                      </span>
                  <label href="" className="btn btn-sm text-dark p-0" onClick={()=>dispatch({type : "ADD", payload: category})}>
                    <i className="fas fa-shopping-cart text-primary mr-1"></i>
                    Add To Cart
                  </label>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    );
}
