import React, { Fragment, useContext } from 'react';
import { Category } from '../Components/Category';
import { TopHeader } from '../Components/TopHeader';
import { Navbar } from '../Components/Navbar';
import { Slider } from '../Components/Slider';
import { PolicyTags } from '../Components/PolicyTags';
import { CategoryCount } from '../Components/CategoryCount';
import { BtmFooter } from '../Components/BtmFooter';
import { OffersDiv } from '../Components/OffersDiv';
import { Cartcontext } from '../context/context';
import { NavLink } from 'react-router-dom';

function Index() {
const Globalstate = useContext(Cartcontext);
    return (
        <Fragment>
            <TopHeader />
            <div className="container-fluid mb-5">
                <div className="row border-top px-xl-5">
                 <Category collapsed={true}/> 
                    <div className="col-lg-9">
                        <Navbar />
                        <Slider />
                    </div>
                </div>
            </div>
            <PolicyTags/>
         {/* <CategoryCount/>  */}
            <div className="container-fluid offer pt-5">
                <div className="row px-xl-5">
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-right text-white mb-2 py-5 px-5">
                            <img src="assets/img/offer-1.png" alt="" />
                            <div className="position-relative" style={{ zIndex: '1' }}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Spring Collection</h1>
                                <NavLink to="/Shop" className="btn btn-outline-primary py-md-2 px-md-3">All Jewellery</NavLink> 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-left text-white mb-2 py-5 px-5">
                            <img src="img/offer-2.png" alt="" />
                            <div className="position-relative" style={{ zIndex: '1' }}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Winter Collection</h1>
                                <NavLink to="/Shop" className="btn btn-outline-primary py-md-2 px-md-3">All Jewellery</NavLink> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
            <OffersDiv/>
            <div className="container-fluid py-5">
                <div className="row px-xl-5">
                    <div className="col">
                        <div className="owl-carousel vendor-carousel">
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-1.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-2.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-3.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-4.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-5.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-6.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-7.jpg" alt="" />
                            </div>
                            <div className="vendor-item border p-4">
                                <img src="assets/img/vendor-8.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
           <BtmFooter/>
        </Fragment>
    );
}
export default Index;