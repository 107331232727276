import React, { createContext, useEffect, useReducer } from "react";

export const Cartcontext = createContext();

// const initialState = {
//   cart:[],
//   total_item: "",
//   total_amount:"",
//   shipping_fee: 100,
// }

export const Context = (props) => {
  
  const reducer = (state, action) => {
    switch (action.type) {
      case "ADD":
        const tempstate = state.filter((item) => action.payload.id === item.id);
        if(tempstate.length>0){
          return state;
        }
        else{
          return [...state, action.payload];
        }
      case "INCREASE":
        const tempstate1 = state.map((item)=>{
          if(item.id === action.payload.id){
            return {...item, qty:item.qty + 1};
          } else{
            return item;
          }
        });
        return tempstate1;
      case "DECREASE" :
        const tempstate2 = state.map((item)=>{
          if(item.id === action.payload.id){    
            return {...item, qty:item.qty - 1};       
          }
          else{
            return item;
          }
        });
        return tempstate2;
      case "REMOVE":
        const tempstate3 = state.filter((item)=>item.id!==action.payload.id)
        return tempstate3;
      case "checkout":
        const tempstate4 = state;
        return tempstate4;
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, []);
  const info = { state, dispatch };

  useEffect(()=>{
   // localStorage.setItem("itemsOfCart", value)
  },[]);

  return (
    <Cartcontext.Provider value={info}>{props.children}</Cartcontext.Provider>
  );
};
