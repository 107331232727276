
import './App.css';
import Index from './Pages/index';
import Cart from './Pages/cart';
import Checkout from './Pages/checkout';
import Contact from './Pages/contact';
import Detail from './Pages/detail';
import Shop from './Pages/shop';
import {Login} from './Pages/login';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkdetail from './Pages/checkDetail';
import { AboutUs } from './Pages/aboutus';
import { Register } from './Pages/register';

function App() {
   
  return (
      <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<Index />}></Route>
              <Route exact path="/shop" element={<Shop />} ></Route>
              <Route exact path="/cart" element={<Cart />}></Route>
              <Route exact path="/checkout" element={<Checkout />}></Route>
              <Route exact path="/contact" element={<Contact />}></Route>
              <Route exact path="/detail" element={<Detail />}></Route>
              <Route exact path="/checkDetail" element={<Checkdetail />}></Route>
              <Route exact path="/login" element={<Login />}></Route>
             <Route exact path="/register" element={<Register/>}></Route>
             <Route exact path="/aboutus" element={<AboutUs/>}></Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
