import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Context } from './context/context';
import { AppProvider } from './context/productcontex';
import { FilterContextProvider } from './context/filter_context';
import { StrictMode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <StrictMode>
 <Auth0Provider
    domain="dev-wcl5mqv5c7uf1szb.us.auth0.com"
    clientId="A5NnVUPQx1ZzAE1iz2wHcpEVRKLDoTD8"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
  <AppProvider>
    <FilterContextProvider>
      <Context>
        <App />
      </Context>
    </FilterContextProvider>
  </AppProvider>  
  </Auth0Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
