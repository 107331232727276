import axios from "axios";
import React, { useEffect, useState , Fragment } from "react";
import { TopHeader } from "../Components/TopHeader";
import { Navbar } from "../Components/Navbar";
import { BtmFooter } from "../Components/BtmFooter";
import { Category } from "../Components/Category";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
export function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <Fragment>
        <TopHeader />
        <div className="container-fluid mb-5">
          <div className="row border-top px-xl-5">
            <Category collapsed={true} />
            <div className="col-lg-9">
              <Navbar />
              <div className="container-fluid bg-secondary mb-5">
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ minHeight: "300px" }}>
                  <h1 className="font-weight-semi-bold text-uppercase mb-3">
                    Login Here
                  </h1>
                  <div className="d-inline-flex">
                    <p className="m-0">
                      <a href="/">Home</a>
                    </p>
                    <p className="m-0 px-2">-</p>
                    <p className="m-0">Login</p>
                  </div>
                </div>
              </div>
             {/* Login Form here */}
             
                        {/* <div className="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" noValidate="novalidate">                                
                                <div className="control-group">
                                    <input type="email" className="form-control" id="email" placeholder="User Id"
                                        required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="password" className="form-control" id="subject" placeholder="Password"
                                        required="required" data-validation-required-message="Please enter a subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button className="btn btn-primary py-2 px-4" type="submit" id="sendMessageButton">Login</button>
                                </div>
                            </form>
                        </div>
                    </div> */}
                   
            </div>
          </div>          
        </div>
        <BtmFooter />
      </Fragment>
      {/* <button onClick={handleAlertClick}>Show Yes or Cancel Alert</button> */}
      {/* <div>
        <section className="overflow-x">
          <div className="horizontal-friends-list">
            {categories.map((category) => (
              <figure key={category}>
                <picture>
                 // <img src="https://randomuser.me/api/portraits/women/2.jpg" /> 
                  <GetImage imgg={category} />
                </picture>
                <figcaption>{category}</figcaption>
              </figure>
            ))}
          </div>
        </section>
      </div> */}
    </div>
  );
};

