import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../Components/Category";
import { Navbar } from "../Components/Navbar";
import { TopHeader } from "../Components/TopHeader";
import { BtmFooter } from "../Components/BtmFooter";
import { SideBarShop } from "../Components/SideBarShop";
import { useFilterContext } from "../context/filter_context";
import { Cartcontext } from "../context/context";


function Shop() {  
  const { filters : {text }, filter_products, sorting, updateFilterValue }=useFilterContext();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [counter, setCounter] = useState(1);
  const Globalstate = useContext(Cartcontext);
  const dispatch = Globalstate.dispatch;
    
  useEffect(() => {     
      setPosts(filter_products);
}, [filter_products]);

  function getSelectedItem(itemId){
     navigate("/detail", {
      state: {
          selectedItem : itemId,
          detailCat : 'this is my fav',
      },
   });
  }
  return (
    <Fragment>
      <TopHeader />
      <div className="container-fluid">
        <div className="row border-top px-xl-5">
          <Category collapsed={false} />
          <div className="col-lg-9">
            <Navbar />
          </div>
        </div>
      </div>
      {/* <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "300px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
            Our Shop
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <a href="/">Home</a>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Shop</p>
          </div>
        </div>
      </div> */}

<div className="container-fluid pt-5">        
        <div className="row px-xl-5">
        <div className="col-lg-3 col-md-12">
          <SideBarShop />
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="row pb-3">
              <div className="col-12 pb-1">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="input-group">
                      <input
                        type="text"
                        name="text"
                        value={text}
                        onChange={updateFilterValue}
                        className="form-control"
                        placeholder="Search for products"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text bg-transparent text-primary">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                  <p className="text-muted ml-2">{posts.length} Products </p>                
                  <div className="dropdown ml-4">
                    <form action="#">
                      <label htmlFor="sort" className="text-muted">Sort by &nbsp;</label>
                      <select
                        className="dropdown btn border dropdown-toggle dropdown-menu-left"
                        name="sort"
                        id="sort"
                        onClick={sorting}>                        
                        <option value="#" disabled></option>
                        <option className="dropdown-item" value="a-z">
                          Name [A-Z]
                        </option>
                        <option value="#" disabled></option>
                        <option className="dropdown-item" value="z-a">
                          Name [Z-A]
                        </option>
                        <option value="#" disabled></option>
                        <option className="dropdown-item" value="lowest">
                          Price [Low - High]
                        </option>
                        <option value="#" disabled></option>
                        <option className="dropdown-item" value="highest">
                          Price [High - Low]
                        </option>
                      </select>
                    </form>
                  </div>
                </div>
                {/* <ShopCat/> */}               
              </div>
              {posts.map((post) => ( 
               post.qty = counter,
              <div className="col-lg-4 col-md-6 col-sm-12 pb-1"
                  key={post.id}
                  onClick={() => {
                    getSelectedItem(post.id);
                  }}
                >
                  <div className="card product-item border-0 mb-4">
                    {/* start from here */}
                    <div onClick={() => { getSelectedItem(post.id); }}>
                    <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                      <img className="img-fluid w-100" src={post.image} alt="" />
                    </div>
                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                      <h6 className="text-truncate mb-3">{post.name}</h6>
                      <div className="d-flex justify-content-center">
                        <h6>₹ {post.price - 1000}</h6>
                        <h6 className="text-muted ml-2">
                          <del>₹ {post.price}</del>
                        </h6>
                      </div>
                    </div>
                    </div>
                    <div className="card-footer d-flex justify-content-between bg-light border">
                      <a className="btn btn-sm text-dark p-0">
                        <i className="fas fa-cogs text-primary mr-1"></i>
                        {post.company}
                      </a>
                      <label href="" className="btn btn-sm text-dark p-0" onClick={()=>dispatch({type : "ADD", payload: post})}>
                        <i className="fas fa-shopping-cart text-primary mr-1"></i>
                        Add To Cart
                      </label> 
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-12 pb-1">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center mb-3">
                    <li className="page-item disabled">
                      <a className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" >
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" >
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BtmFooter />
    </Fragment>
  );
}
export default Shop;

/* {
  // if (location.state === null) {
    //   fetch(url)
    //   .then((resp) => resp.json()) //calling url by method GET
    //   .then((resp) =>
    //     setPosts(
    //       resp.filter((item) => item.category ))
    //   );      
    // } else {
    //   fetch(url)
    //   .then((resp) => resp.json()) //calling url by method GET
    //   .then((resp) =>
    //     setPosts( 
    //       resp.filter((item) => item.category === location.state.selectedCat))
    //   );
    // }
    //   console.log(all_products);
} */
