import React, { useContext, useEffect } from "react";
import { Cartcontext } from "../context/context";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export function TopHeader() {

    const Globalstate = useContext(Cartcontext);
    const state = Globalstate.state;
    const dispatch = Globalstate.dispatch;
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

    useEffect(()=>{        
    });

    return(
        <div className="container-fluid">
        <div className="row bg-secondary py-2 px-xl-5">
            <div className="col-lg-6 d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <a className="text-dark" href="">FAQs</a>
                    <span className="text-muted px-2">|</span>
                    <a className="text-dark" href="">Help</a>
                    <span className="text-muted px-2">|</span>
                    <a className="text-dark" href="">Support</a>
                </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                    <a className="text-dark px-2" href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-dark px-2" href="https://twitter.com/i/flow/login">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="text-dark px-2" href="https://in.linkedin.com/">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a className="text-dark px-2" href="https://www.instagram.com/">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="text-dark pl-2" href="https://www.youtube.com/">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
        <div className="row align-items-center py-3 px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
                <a href="/" className="text-decoration-none">
                    <h1 className="m-0 display-5 font-weight-semi-bold">
                        <span className="text-primary font-weight-bold border px-3 mr-1">E</span>
                        Shopper
                    </h1>
                </a>
            </div>
             <div className="col-lg-6 col-6 text-left">
                {/* <form action="">
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search for products" />
                    <div className="input-group-append">
                        <span className="input-group-text bg-transparent text-primary">
                            <i className="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                </form> */}
                </div> 
            <div className="col-lg-3 col-6 text-right">
            {isAuthenticated ? (   <span className="text-muted">
                {user.name } &nbsp;
            </span>     ) : (
                <span className="text-muted">
                 
            </span>  
            )}
            
                    <a href="" className="btn border">
                        <i className="fas fa-heart text-primary"></i>
                        <span className="badge">0</span>
                    </a>
                    <span>{" "}</span>
                    <NavLink to="/Cart" className="btn border">
                       <i className="fas fa-shopping-cart text-primary"></i>
                        <span className="badge">{state.length}</span>
                    </NavLink>
            </div>
        </div>
    </div>      
    );
}

