import React, { Fragment } from "react";
import { TopHeader } from "../Components/TopHeader";
import { Navbar } from "../Components/Navbar";
import { BtmFooter } from "../Components/BtmFooter";
import { Category } from "../Components/Category";

export function Register() {
  return (
    <>
      <Fragment>
        <TopHeader />
        <div className="container-fluid mb-5">
          <div className="row border-top px-xl-5">
            <Category collapsed={true} />
            <div className="col-lg-9">
              <Navbar />
              <div className="container-fluid bg-secondary mb-5">
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ minHeight: "300px" }}
                >
                  <h1 className="font-weight-semi-bold text-uppercase mb-3">
                    Register Here
                  </h1>
                  <div className="d-inline-flex">
                    <p className="m-0">
                      <a href="/">Home</a>
                    </p>
                    <p className="m-0 px-2">-</p>
                    <p className="m-0">Registration</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BtmFooter />
      </Fragment>
    </>
  );
}
